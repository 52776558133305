import { createSlice } from '@reduxjs/toolkit';

const initialState = { activeConversationUuid: null, isTopicVisible: false, isTopicClickable: true, failedFetchQuestionsConversationUuid: null };

export const ConversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        setActiveConversationUuid: (state, action) => {
            state.activeConversationUuid = action.payload;
            state.isTopicVisible = false;
        },
        setTriggerTopicFocus: (state, action) => {
            state.isTopicVisible = action.payload;
        },
        setIsTopicClickable: (state, action) => {
            state.isTopicClickable = action.payload;
        },
        setFailedFetchQuestionsConversationUuid: (state, action) => {
            state.failedFetchQuestionsConversationUuid = action.payload;
        },
    }
});

export const { setActiveConversationUuid, setTriggerTopicFocus, setIsTopicClickable, setFailedFetchQuestionsConversationUuid } = ConversationSlice.actions;

export const selectActiveConversationUuid = (state) => state.conversation.activeConversationUuid;
export const selectIsTopicClickable = (state) => state.conversation.isTopicClickable;
export const selectTriggerTopicFocus = (state) => state.conversation.isTopicVisible;
export const selectFailedFetchQuestionsConversationUuid = (state) => state.conversation.failedFetchQuestionsConversationUuid;

export default ConversationSlice.reducer;