import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { selectInputMessage, selectLineBreak, selectSend, selectUrlText, setInputMessage, setLineBreak, setSend, setUrlText, setUrlSource, selectUrlSource } from '../../slice/InputMessageSlice';
import { deleteMessage, selectMessages, setMessage, updateMessage, updateMessageText, setAllTopicMessagesAsObsolete } from '../../slice/MessageSlice';
import { useEffect, useRef, useState } from 'react';
import { sendMessageGA } from '../../GoogleAnalytics4/GoogleAnalyticsEvents';
import { selectStreaming, setShowLoader, setStreaming, selectTranslating, selectShowLoader, setWaitingForMaxQueryResponse, selectWaitingForMaxQueryResponse } from '../../slice/loaderSlice';
import { Url } from '../../constants/global';
import { useCacheBuster } from "react-cache-buster";
import { selectBearer, setExpired, selectController, setController } from '../../slice/bearerSlice';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { selectUserData } from '../../slice/userDataSlice';
import SendArrow from '../../Images/SendArrow';
import { selectRequest, setRequest } from '../../slice/userActionsSlice';
import { setShowLogin } from '../../slice/ModalVisibilitySlice';
import { validateVariant } from './validateFunction';
import citiesCountryMapping from '../../constants/citiesCountryMapping';
import { setRefreshProfileHistory } from '../../slice/profileHistorySlice';
import { setShowMaximumQueryLimit } from "../../slice/ModalVisibilitySlice";
import { setCounterPlusOne, selectQueryDoneCounter, selectCounterInitTime, selectCounterResetTime } from "../../slice/unloggedUserSlice";
import { setActiveConversationUuid, setIsTopicClickable } from "../../slice/ConversationSlice";

function SendMessageButton() {
    const inputMessage = useSelector(selectInputMessage);
    const messages = useSelector(selectMessages);
    const send = useSelector(selectSend);
    const buttonRef = useRef(null);
    const dispatch = useDispatch();
    const streaming = useSelector(selectStreaming);
    const translating = useSelector(selectTranslating);
    const loader = useSelector(selectShowLoader);
    const [disable, setDisable] = useState(false);
    const reload = useRef(true);
    const text = useSelector(selectUrlText);
    const linebreak = useSelector(selectLineBreak);
    const urlParams = new URLSearchParams(window.location.search);
    const { checkCacheStatus } = useCacheBuster();
    const controller = useSelector(selectController);
    const signal = controller.signal;
    const user = useSelector(selectUserData).user;
    const [stopSearch, setStopSearch] = useState(false);
    const nRequest = useSelector(selectRequest);
    const logToken = useSelector(selectBearer);
    const unloggedUserQueryDone = useSelector(selectQueryDoneCounter);
    const unloggedUserCounterInitTime = useSelector(selectCounterInitTime);
    const NOT_LOGGED_USER_MAX_ALLOWED_QUERIES = 5;
    const NOT_LOGGED_USER_COUNTER_RESET_TIME = useSelector(selectCounterResetTime);
    const waitingForMaxQueryResponse = useSelector(selectWaitingForMaxQueryResponse);
    const [triggerAfterUpdatingBearer, setTriggerAfterUpdatingBearer] = useState(false);
    const urlSource = useSelector(selectUrlSource);


    useEffect(() => {
        if (window.location.pathname === '/search' && urlParams.get('text') !== null && text !== urlParams.get('text')) {
console.log('--> search', window.location.search, window.location.pathname, urlParams, urlParams.get('text'))
            dispatch(setUrlText(urlParams.get('text')));
            dispatch(setInputMessage(urlParams.get('text')));
            dispatch(setSend(!send));
            dispatch(setLineBreak(true));
            dispatch(setUrlSource(getExternalLinkSource()));
        } else if (!reload.current || send === "send") {
            console.log('send', reload.current, send, send === "send")
            if(urlSource != 'ucsc') dispatch(setUrlSource("")); // se da ucsc mantengo l'ambiente d'origine per il check sull'unlogged modal
            buttonRef.current.click();
        } else {
            dispatch(setUrlSource(""));
        }
        reload.current = false;
    }, [send])

    useEffect(() => {
        if(streaming) {
            const onBeforeUnload = (e) => {
                if (streaming) {
                    //e.preventDefault(); dialog
                    controller.abort();
                    dispatch(setController(new AbortController()));
                    if(messages[messages.length - 1].type === 'topic') {
                        dispatch(updateMessage({ i: messages.length - 2, obj: { stopText: '\nVariant search has been interrupted by the user.' } }));
                        dispatch(deleteMessage());
                    } else {
                        if (loader) {
                            dispatch(updateMessage({ i: messages.length - 1, obj: { stopText: 'Variant search has been interrupted by the user.', text: '' } }))
                        } else {
                            dispatch(updateMessage({ i: messages.length - 1, obj: { stopText: '\nVariant search has been interrupted by the user.' } }))
                        }
                    }
                }
                //e.returnValue = streaming;
                //return streaming;
            };

            window.addEventListener('beforeunload', onBeforeUnload);

            return (res) => {
                window.removeEventListener('beforeunload', onBeforeUnload);
            };
        }
    }, [streaming]);

    useEffect(() => {
        if (translating) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [translating])

    useEffect(() => {
        if (stopSearch) {
            setStopSearch(false);
            dispatch(setStreaming(false));
        }
    }, [stopSearch])

    useEffect(()=>{
        if(triggerAfterUpdatingBearer){ //To trigger the function that had given 401 because the token had expired
            setTriggerAfterUpdatingBearer(false);
            if(logToken !== '') checkLoggedUserMAxQueries();
        }
    }, [logToken])

    async function clickSendButton(e) {
        if (streaming) {
            stopSearching();
        } else {
            await sendMessage();
        }
    }

    function stopSearching() {
        controller.abort();
        dispatch(setController(new AbortController()));
        if(messages[messages.length - 1].type === 'topic') {
            dispatch(updateMessage({ i: messages.length - 2, obj: { stopText: '\nVariant search has been interrupted by the user.' } }));
            dispatch(deleteMessage());
        } else {
            if (loader) {
                dispatch(updateMessage({ i: messages.length - 1, obj: { stopText: 'Variant search has been interrupted by the user.', text: '' } }))
                dispatch(setShowLoader(false));
            } else {
                dispatch(updateMessage({ i: messages.length - 1, obj: { stopText: '\nVariant search has been interrupted by the user.' } }))
            }
        }
        setStopSearch(true);
    }

    function getCountry() {
        try {
            return citiesCountryMapping[Intl.DateTimeFormat().resolvedOptions().timeZone.split('/')[1]];
        } catch(e) {
            return '';
        }
    }
    
    function getExternalLinkSource() {
        try {
            console.log('getExternalLinkSource', window.location.search.split('source=')[1].split('&')[0])
            return window.location.search.split('source=')[1].split('&')[0];
        } catch(e) {
            console.log(e)
            return '';
        }
    }

    function checkIfCreateWantToKnowMoreMessageForUnlogged(statusCode) {
        if(!logToken) {
            if(statusCode === 0) {
                dispatch(setMessage({
                    text: '{"chat":[{"topic":"How is this linked to diseases?"},{"topic":"What treatments are associated?"},{"topic":"What is the impact of this?"}]}',
                    user: 'VarChat',
                    type: 'topic',
                    isExampleTopic: true,
                    language: 'EN',
                    statusCode: 0,
                    statusInfoCode: 0,

                }));
            }
        }
    };

    async function actuallySendMessage() {
        checkCacheStatus();
        sendMessageGA();
        const validation = validateVariant(inputMessage);
        const text = validation.text;
        dispatch(setMessage({ text: inputMessage, user: 'You', linebreak: linebreak }));
        dispatch(setInputMessage(''));
        dispatch(setStreaming(true));
        dispatch(setLineBreak(false));
        if (validation.val) {
            const filteredParams = [text.rs, text.hgvs_c, text.hgvs_p, text.genomic_coord, text.hgvs_m].filter((str) => str !== '');
            let loading_message = '';
            if(filteredParams.length !== 0){
                loading_message = 'Searching for variant ' + filteredParams.join(', ') + (text.transcript !== '' ? ' on transcript ' + text.transcript : '') + (text.gene !== "" ? ' on gene ' + text.gene : "");
            } else {
                loading_message = 'Searching for gene ' + text.gene;
            }
            dispatch(setMessage({ text: loading_message, user: 'VarChat' }))
            try {
                const param = Object.assign({}, text, { stream: true, user: user, user_input: inputMessage.trim(), country: getCountry(), source: urlSource });
                await streamSummary(param, text);
            } catch (error) {
                console.error(`stream: ${error}`);
            }
        } else {
            dispatch(setMessage({ text: 'Searching ' + inputMessage, user: 'VarChat' }));
            try {
                const param = { gene: '', rs: '', hgvs_c: '', hgvs_p: '', hgvs_m: '', transcript: '', genomic_coord: '', stream: true, user: user, user_input: inputMessage.trim(), country: getCountry(), source: urlSource };
                await sendVariantValidateFalse(param, text);
                dispatch(setRefreshProfileHistory(true));
            } catch (error) {
                console.error(`send: ${error}`);
            }

            setTimeout(() => {
                dispatch(deleteMessage());
                dispatch(setStreaming(false));
                dispatch(setMessage({ text: text, user: 'VarChat', statusCode: validation.status }));
            }, 2000)
        }
    }

    async function checkLoggedUserMAxQueries() {
        if(logToken) {
            dispatch(setWaitingForMaxQueryResponse(true));
            dispatch(setIsTopicClickable(false)); // disabilito l'evetuale topic gia esistente mentre qua in streaming 

            const loggedMaxQueriesPromise = fetch(Url + '/varchat/api/user-info/query-limit-check', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
            });
            loggedMaxQueriesPromise.then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        dispatch(setExpired(true));
                        setTriggerAfterUpdatingBearer(true);
                    }
                    if (response.status === 409) {
                        dispatch(setShowMaximumQueryLimit(true));
                    }
                    dispatch(setWaitingForMaxQueryResponse(false));
                    throw new Error(`Limit query 409 error: ${response.status}`);
                } else {
                    dispatch(setWaitingForMaxQueryResponse(false));
                    actuallySendMessage();
                }
            }).catch((error, x) => {
                dispatch(setWaitingForMaxQueryResponse(false));
                dispatch(setIsTopicClickable(true));
                dispatch(setStreaming(false));
            });
        }
    }

    async function sendMessage() {
        if (inputMessage.trim(' ') !== '') {
            /* dispatch(setStreaming(true));
            dispatch(setLineBreak(false)); */
            if (logToken === '') {
                dispatch(setAllTopicMessagesAsObsolete()); // per il discover more degli unlogged

                dispatch(setRequest(nRequest + 1));
                dispatch(setCounterPlusOne(Date.now()));
                if(!(urlSource === 'ucsc' && unloggedUserQueryDone <= 3)) {
                    setTimeout(function() { // fix the already active validation when coming from unlogged user max query limit
                        dispatch(setShowLogin({ show: true, type: 'unloggedUserQueryLimit' }));
                    }, 100);
                }
                if(Date.now() - unloggedUserCounterInitTime > NOT_LOGGED_USER_COUNTER_RESET_TIME) {
                    await actuallySendMessage();
                } else {
                    if(unloggedUserQueryDone < NOT_LOGGED_USER_MAX_ALLOWED_QUERIES) {
                        await actuallySendMessage();
                    }
                }
                dispatch(setActiveConversationUuid(nRequest + "unlogged_fake_topic")); // per il discover more degli unlogged
            } else {
                dispatch(setActiveConversationUuid(null));
                await checkLoggedUserMAxQueries();
            }            
        }
    }

    async function sendVariantValidateFalse(param, text) {
        let headers;
        logToken
            ? headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${logToken}`}
            : headers = {'Content-Type': 'application/json'};
        
        await fetchEventSource(Url + '/varchat/api/request/?' + new URLSearchParams(param), {
            method: 'GET',
            headers: headers,
            openWhenHidden: true,
            signal: signal,
            onopen(response) {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return;
                }
            },
            onclose() {
                dispatch(setIsTopicClickable(true));
                console.log('close1');
            },
            onerror(error) {
                dispatch(setIsTopicClickable(true));
                throw error;
            }
        });
    }

    async function streamSummary(param, text) {
        let statusCode;
        let conversationUuid;
        let jsonData = {};
        let headers;
        logToken
            ? headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${logToken}`}
            : headers = {'Content-Type': 'application/json'};
        await fetchEventSource(Url + '/varchat/api/request/?' + new URLSearchParams(param), {
            method: 'GET',
            headers: headers,
            openWhenHidden: true,
            signal: signal,
            onopen(response) {
                dispatch(setRefreshProfileHistory(true));
                if (!response.ok) {
                    if(response.status === 401) {
                        setTimeout(function() { // fix the already active validation when coming from unlogged user max query limit
                            dispatch(setShowLogin({ show: true, type: 'unloggedUserQueryLimit' }));
                        }, 100);
                    }
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return;
                }
            },
            onmessage(msg) {
                try {
                    const data = JSON.parse(msg.data);
            //        console.log('onmessage data', data);
                    if (Object.keys(jsonData).length === 0) {
                        console.log('initial data', JSON.parse(msg.data))
                        statusCode = data.status_answer_code;
                        dispatch(deleteMessage());
                        dispatch(setShowLoader(false));
                        dispatch(setMessage({
                            text: "",
                            user: 'VarChat',
                            userInput: text,
                            statusCode: data.status_answer_code,
                            language: 'EN',
                            statusInfoCode: data.status_info_code
                        }));
                        jsonData = {
                            evaluate: true,
                            cits: data.cits,
                            citCount: data.cit_count,
                            rcvCitCount: data.rcv_cit_count,
                            rcvCits: data.rcv_cits,
                            triggeringQuestionLambda: data.triggering_question_lambda,
                            conversationUuid: logToken ? data.conversation_uuid : (nRequest + "unlogged_fake_topic") // per il discover more degli unlogged
                        };
                        if(data.triggering_question_lambda) {
                            dispatch(setMessage({ 
    //text: '{"chat":[{"topic":"Parkinsons disease and PINK1 mutation","topic_uuid":"b6f02b53-1948-45af-b47a-c46885a4f10d","questions":[{"question":"What is the role of the PINK1 G309D mutation in the development of Parkinsons disease?","question_uuid":"e920a82b-3046-4867-843c-52139538cbb1"},{"question":"How does the PINK1 G309D mutation affect mitochondrial function and oxidative stress in Parkinsons disease?","question_uuid":"14f61b6c-5232-4932-83a9-5c4cf5ac8e96"}]},{"topic":" Treatment implications of PINK1 mutation","topic_uuid":"46535f00-7048-4fc6-8411-4f5ebc0feba3","questions":[{"question":"How does the PINK1 G309D mutation influence the effectiveness of proteasome inhibitors in treating Parkinsons disease?","question_uuid":"dbf46a6f-5164-4d65-a2fc-7b61702df0f9"}]},{"topic":" PINK1 mutation and kinase activity","topic_uuid":"c60d5608-385c-4132-8292-ef10a4bb2a5d","questions":[{"question":"What impact does the PINK1 G309D mutation have on the kinase activity of the PINK1 protein?","question_uuid":"cca5aa6b-7e5a-4ec8-9d8b-110a60ac828d"}]},{"topic":" Tau phosphorylation and neurodegeneration","topic_uuid":"2d700926-ae21-4ce4-8be3-871ed3c6e0c5","questions":[{"question":"How does the PINK1 G309D mutation affect tau phosphorylation and its implications for neurodegenerative diseases?","question_uuid":"7b8b9e8f-1056-4e78-a7c6-2c1dd77b7d87"}]}],"input_tokens":3318,"status_answer_code":0,"output_tokens":159,"date_time":"2024-10-10","model":"gpt-4o","conversation_uuid":"a63de090-eacc-4bb3-97c0-267ec960a587","time":3.260653018951416}',
                                text: '{"chat":[]}',
                                user: 'VarChat',
                                type: 'topic',
                                language: 'EN',
                                conversationUuid: data.conversation_uuid,
                                statusCode: data.status_answer_code,
                                statusInfoCode: data.status_answer_code,
                                cits: data.cits,
                                citCount: data.cit_count
                            }));
                            conversationUuid = data.conversation_uuid;
                        }
                    } else {
                        if (data.status_answer_code !== undefined) {
                            jsonData = Object.assign(jsonData, { statusCode: data.status_answer_code });
                            // console.log('dati', jsonData, 'dataa', data);
                        } else {
                            dispatch(updateMessageText({ i: messages.length + 1, text: data }));
                            // console.log('status answer undefined', data)
                        }
                    }
                } catch {
                    const word = msg.data === '' ? '\n' : msg.data;
                    // console.log('data word', msg.data, 'word', word)
                    dispatch(updateMessageText({ i: messages.length + 1, text: word }));
                }
            },
            onclose() {
                dispatch(updateMessage({ i: messages.length + 1, obj: jsonData }))
                dispatch(setStreaming(false)); //moved to use effect of messages (for scrolling purposes) // rimesso anche qui
                if(conversationUuid) {
                    dispatch(setActiveConversationUuid(conversationUuid));
                }
                dispatch(setIsTopicClickable(true));
                checkIfCreateWantToKnowMoreMessageForUnlogged(statusCode);
            },
            onerror(error) {
                console.log('error', error)
                error += "";
                dispatch(setStreaming(false));
                dispatch(deleteMessage());
                dispatch(setMessage({ user: 'VarChat', statusCode: error.includes('401') ? 12 : 5 }));
                dispatch(setIsTopicClickable(true));
                throw error;
            }
        });
    }

    return (
        <div name='send-button-div'>
            <span>{!streaming ? 'SEND' : 'STOP'}</span>
            <Button name='send-button' onClick={clickSendButton} ref={buttonRef} disabled={waitingForMaxQueryResponse ? true : disable}>
                {streaming ? <FontAwesomeIcon icon={faStop} /> : <SendArrow />}
            </Button>
        </div>
    );
}

export default SendMessageButton;