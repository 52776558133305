import React from 'react';
import robot from '../../Images/varchat-icon-robot-2.svg';
import robotDark from '../../Images/varchat-icon-robot-dark.svg';
import user from '../../Images/varchat-icon-user-2.svg';
import userDark from '../../Images/varchat-icon-user-dark.svg';
import { forwardRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessages, updateMessage } from '../../slice/MessageSlice';
import { isMobile } from 'react-device-detect';
import { selectLanguage, selectTheme } from '../../slice/SettingSlice';
import { setShowLogin } from '../../slice/ModalVisibilitySlice';
import Loader from './Loader/Loader';
import { selectBearer } from '../../slice/bearerSlice';
import { selectStreaming, selectTranslating, selectWaitingForMaxQueryResponse } from '../../slice/loaderSlice';
import ReadMore from './ReadMore';
import ErrorIcon from '../../Images/ErrorIcon';


const FakeMessageTopic = forwardRef(function Message(props, ref) {
    const dispatch = useDispatch();
    const messages = useSelector(selectMessages);
    let message = messages[props.ind];
    message.mex ? message = message.mex : message = message;
    const theme = useSelector(selectTheme);
    const robotIcon = theme === 'light' ? robot : robotDark;
    const userIcon = theme === 'light' ? user : userDark;
    const language = useSelector(selectLanguage);
    const logToken = useSelector(selectBearer);
    const translating = useSelector(selectTranslating);
    const streaming = useSelector(selectStreaming);
    const [isFirstRender, setIsFirstRender] = useState(true); // al primo rendere non deve essere mostrato finchè non termina la ricerca
    const waitingForMaxQueryResponse = useSelector(selectWaitingForMaxQueryResponse);
    let messageBody = '';
    //metto il messaggio dell'errore di traduzione perché non si può salvare in uno stato un html
    const translationError = <span className='error'><ErrorIcon /><span>I was unable to complete the translation right now. Please try again later. <br />If the problem persists, please contact <a className='link-error' href='mailto:varchat@engenome.com'>varchat@engenome.com</a></span></span>;
    const translation = (message.translation && message.translation[message.language] !== undefined ? (message.translation[message.language] === 'error' ? (translating ? <Loader loading_message={'TRANSLATING...'} /> : translationError) : message.translation[message.language]) : <Loader loading_message={'TRANSLATING...'} />); //in message.translation mi salvo le n traduzioni nelle varie lingue

    const [responseJSON, setResponseJSON] = useState(null);
       
    useEffect(() => { //to set time only once
        if (!message.time) {
            dispatch(updateMessage({ i: props.ind, obj: { time: getFormattedTime() } }))
        }
    }, []);

    function getFormattedTime() {
        const date = new Date();
        const timeComponents = [date.getHours(), date.getMinutes()];
        return timeComponents
            .map(component => {
                const pad = (component < 10) ? '0' : '';
                return pad + component;
            })
            .join(':');
    }

    const onClick = () => {
        dispatch(setShowLogin({ show: true, type: 'fakeTopic' }));
    }

    const isMessageVisible = () => {
        try {
            return message.isObsolete !== true;
        } catch(e) {
            return true;
        }
    }

    return (
        <> { !isMessageVisible()
                ? ""
                : <>
                    <span className={'message'} ref={ref} key={'message ' + props.ind}>
                        {isFirstRender ? (!waitingForMaxQueryResponse && !streaming) ? setIsFirstRender(false) : null : null}
                        {isMobile
                            ? null
                            : message.user === 'VarChat'
                                ?   <img src={robotIcon} alt='varChat' className='icon'></img>
                                :   <img src={userIcon} alt='user' className='icon'></img>}

                        <span name='message-text' className={message.user.toLowerCase()}>
                            <span name='message-header'>
                                <span>
                                    {!isMobile
                                        ? null
                                        : message.user === 'VarChat'
                                            ?   <img src={robotIcon} alt='varChat' className='icon' style={{ marginRight: '8px' }}></img>
                                            :   <img src={userIcon} alt='user' className='icon' style={{ marginRight: '8px' }}></img>}
                                    <span style={{ display: isMobile ? 'block' : 'flex' }}>
                                        <p name='name'>{message.user}</p>
                                        <p name='time'>{message.time}</p>
                                    </span>
                                </span>

                            </span>
                            <div style={{ whiteSpace: 'pre-line', lineBreak: (message.linebreak !== undefined && message.linebreak) ? 'anywhere' : 'auto' }}>
                                {/* message body cambia in base alla lingua */}
                                <div style={{ direction: message.language === 'AR' ? 'rtl' : '' }}>
                                    {messageBody}
                                </div>
                            </div>

                            { JSON.parse(message.text).chat.map((chat, j) => {
                                return <>
                                <div className='message-section' onClick={onClick}>
                                    <div name='ref-title'>
                                        <span>
                                            <p style={{fontSize: '16px'}}>{chat.topic}</p>
                                        </span>
                                        <ReadMore expand={false} messageShow={"SEE ANSWER"} messageHide={"HIDE ANSWER"} />
                                    </div>
                                </div></>
                            }) }

                        </span>
                    </span>
                </>
            }
        </>
        
    );
})

export default FakeMessageTopic;